import React, { Component } from 'react';
import axios from 'axios';
import Loader from './loader';

// Define the loading animation component as a class component
class LoadingAnimation extends Component {
  render() {
    // Define inline CSS styles for the loading animation
    const loaderStyle = {
      border: '16px solid #f3f3f3',
      borderTop: '16px solid #3498db',
      borderRadius: '50%',
      width: '120px',
      height: '120px',
      animation: 'spin 2s linear infinite',
      margin: 'auto'
    };

    return <div style={loaderStyle}></div>;
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      token: null
    };
  }

  async componentDidMount() {
    try {
      let storedToken = localStorage.getItem('token');
      // const ipjson = await axios.get('https://api.ipify.org?format=json')
      // console.log('here')
      // console.log({ipjson})
      // Parse the URL to extract the query parameters
      const urlParams = new URLSearchParams(window.location.search);
      const promoterParam = urlParams.get('promoter');
      if (storedToken) {
        console.log('got token alrd')
        // If token exists in localStorage, use it
        this.setState({ token: storedToken });
        const response = await axios.post('/generate', { mytoken: storedToken, ip: null, promoterParam });
        const {promoterCode} = response.data
        if (response.status === 200) {
          window.history.replaceState(null, '', window.location.href);
          window.location.replace(`https://www.family.abbott/my-en/ensure/tools-and-resources/tools/muscle-age-calculator.html/?utm_source=Promoter&utm_content=${promoterCode}&utm_campaign=MAC_Promoter&utm_medium=QRcode`); // For older browsers
          return; // Exit the function to prevent further execution
        }
      } else {
        // If no token in localStorage, make API call to generate a new token
        const response = await axios.post('/generate', { mytoken: null, ip: null, promoterParam });
        const { token, promoterCode } = response.data;
        this.setState({ token });
        localStorage.setItem('token', token);
        if (response.status === 200) {
          window.history.replaceState(null, '', window.location.href);
          window.location.replace(`https://www.family.abbott/my-en/ensure/tools-and-resources/tools/muscle-age-calculator.html/?utm_source=Promoter&utm_content=${promoterCode}&utm_campaign=MAC_Promoter&utm_medium=QRcode`); // For older browsers
          return; // Exit the function to prevent further execution
      }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, token } = this.state;

    return (
      <div
          style={{
              textAlign: "center",
              margin: "auto",
          }}
      >
          <h1 style={{ color: "#08b6f0" }}>
              Redirecting...
          </h1>
          {loading ? (
              <div
                  style={{
                      width: "100px",
                      margin: "auto",
                  }}
              >
                  <Loader />
              </div>
          ) : (
              <div>
                  <h3>
                     Redirecting
                  </h3>
              </div>
          )}
      </div>
    );
  }
}
export default App;